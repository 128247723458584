#root,
html,
body {
  height: 100vh;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #151515;
  color: whitesmoke;
}

* {
  box-sizing: border-box;
}
